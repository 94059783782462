import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1078.000000 834.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,834.000000) scale(0.100000,-0.100000)">



<path d="M0 4170 l0 -4170 5390 0 5390 0 0 4170 0 4170 -5390 0 -5390 0 0
-4170z m5655 2431 c33 -3 -64 -6 -217 -6 -152 0 -274 2 -271 5 16 17 332 18
488 1z m115 -21 c11 -8 7 -9 -15 -4 -37 8 -45 14 -19 14 10 0 26 -5 34 -10z
m-775 -10 c-11 -5 -29 -8 -40 -8 -16 0 -15 2 5 8 34 11 60 11 35 0z m898 -10
c32 -6 56 -14 54 -17 -6 -6 -157 27 -157 34 0 3 10 3 23 0 12 -3 48 -11 80
-17z m-974 -4 c-2 -2 -17 -6 -34 -10 -22 -5 -26 -4 -15 4 14 9 59 15 49 6z
m-109 -26 c-8 -5 -19 -10 -25 -10 -5 0 -3 5 5 10 8 5 20 10 25 10 6 0 3 -5 -5
-10z m1200 0 c11 -8 7 -9 -15 -4 -37 8 -45 14 -19 14 10 0 26 -5 34 -10z
m-277 -33 c38 -7 65 -16 60 -21 -4 -5 3 -5 16 -1 13 4 40 3 60 -3 20 -6 74
-20 121 -32 47 -12 90 -25 95 -30 6 -4 44 -19 85 -33 70 -24 259 -112 270
-126 3 -3 21 -14 40 -25 120 -64 165 -97 325 -232 143 -121 198 -179 316 -334
141 -185 262 -421 334 -650 13 -41 26 -88 29 -103 3 -16 9 -43 15 -60 41 -128
64 -519 42 -702 -23 -194 -68 -391 -110 -490 -5 -11 -14 -35 -21 -52 -7 -18
-16 -33 -21 -33 -13 0 -12 8 21 106 33 95 73 251 90 349 37 218 28 568 -20
793 -20 89 -79 283 -107 352 -57 136 -188 373 -229 415 -8 8 -12 21 -8 27 4 7
3 9 -1 5 -5 -4 -14 1 -19 11 -21 38 -196 238 -224 255 -7 5 -10 13 -6 18 3 5
2 8 -3 7 -5 -2 -42 27 -83 64 -216 193 -523 362 -797 438 -46 12 -81 26 -78
30 2 4 -7 5 -20 2 -14 -2 -36 3 -51 13 -15 9 -23 12 -19 6 7 -12 -15 -9 -80
10 -16 4 -104 13 -195 19 -149 10 -210 9 -405 -5 -57 -4 -138 -18 -179 -31
-12 -4 -16 -2 -12 5 8 12 -6 15 -18 4 -16 -16 -80 -31 -100 -23 -18 7 -15 9
19 15 22 3 52 11 67 17 15 5 42 7 60 3 26 -5 30 -4 18 5 -12 9 -10 10 12 6 15
-3 37 -1 50 4 46 21 177 29 383 24 116 -2 241 -10 278 -17z m382 3 c13 -5 14
-9 5 -9 -8 0 -24 4 -35 9 -13 5 -14 9 -5 9 8 0 24 -4 35 -9z m-1470 -20 c-3
-5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m1602 -31 c78 -32 127
-59 107 -59 -5 0 -14 4 -19 9 -6 5 -41 21 -80 36 -38 15 -81 34 -95 42 -33 19
-8 11 87 -28z m-1409 -20 c9 0 -19 -11 -63 -23 -44 -13 -106 -33 -137 -45 -32
-12 -60 -22 -63 -22 -3 1 -44 -19 -92 -44 -48 -24 -89 -42 -92 -40 -6 7 -7 6
89 53 116 56 208 92 239 92 21 0 23 2 11 10 -11 8 -7 9 14 4 21 -5 27 -4 22 5
-6 9 0 10 21 6 23 -5 25 -4 13 5 -13 10 -12 11 3 6 10 -3 26 -6 35 -7z m-388
-33 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m1945 -16 c3 -6
-1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z m126 -65 c133 -77 324 -213
314 -224 -3 -2 -26 14 -52 36 -42 34 -167 119 -283 191 -49 30 -32 28 21 -3z
m-2261 -19 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m115 -55
c-6 -5 -26 -17 -45 -28 -38 -21 -139 -84 -182 -114 -16 -11 -25 -14 -22 -6 2
7 30 28 62 46 31 19 70 45 85 58 16 13 35 23 43 23 8 0 14 4 14 9 0 5 10 12
23 14 12 2 24 5 27 6 2 0 0 -3 -5 -8z m2655 -327 c175 -185 347 -443 427 -639
7 -17 22 -52 33 -80 29 -67 50 -135 41 -135 -4 0 -15 26 -26 58 -28 83 -117
281 -153 341 -18 28 -32 54 -32 56 0 10 -55 91 -140 205 -47 63 -141 171 -210
240 -69 68 -98 101 -65 73 33 -28 89 -82 125 -119z m-2960 131 c-7 -8 -17 -15
-23 -15 -6 0 -2 7 9 15 25 19 30 19 14 0z m-70 -64 c-25 -22 -47 -37 -50 -35
-6 6 47 54 60 54 5 0 10 5 10 10 0 6 6 10 13 10 6 0 -8 -18 -33 -39z m-84 -76
c-8 -8 -18 -15 -22 -15 -3 0 -1 7 6 15 7 8 17 15 22 15 5 0 3 -7 -6 -15z
m-112 -114 c3 -4 -5 -18 -18 -30 -18 -17 -26 -19 -31 -10 -5 7 -4 10 2 6 11
-7 45 28 36 37 -4 3 -4 6 0 6 3 0 8 -4 11 -9z m-171 -46 c-3 -9 -8 -14 -10
-11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m77 -69 c0 -2 -8 -10 -17 -17 -16 -13
-17 -12 -4 4 13 16 21 21 21 13z m-76 -101 c-9 -14 -18 -25 -21 -25 -5 0 5 18
20 38 17 22 18 13 1 -13z m-52 -87 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2
-3 -1 -11 -7 -17z m-65 -103 c0 -8 -4 -12 -9 -9 -5 3 -6 10 -3 15 9 13 12 11
12 -6z m-60 -137 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m4098
-238 c5 -17 4 -22 -3 -15 -5 6 -13 21 -17 35 -5 17 -4 22 3 15 5 -5 13 -21 17
-35z m28 -102 c49 -209 72 -445 63 -627 -7 -131 -27 -296 -46 -371 -6 -25 -15
-63 -20 -86 -13 -61 -78 -258 -101 -307 -16 -34 -19 -36 -14 -12 4 17 13 44
20 60 32 70 94 287 114 400 49 277 41 635 -22 913 -13 56 -21 102 -17 102 3 0
14 -33 23 -72z m-2554 -325 c-12 -15 -12 -16 4 -10 13 5 17 3 15 -5 -8 -22
-38 -22 -38 0 0 21 8 32 23 32 5 0 3 -8 -4 -17z m391 2 c15 -18 5 -35 -20 -35
-10 0 -22 5 -25 10 -7 11 12 40 25 40 4 0 13 -7 20 -15z m368 -13 c2 -14 -2
-22 -12 -22 -19 0 -29 17 -22 36 8 22 30 12 34 -14z m376 12 c8 -21 -1 -34
-24 -34 -22 0 -33 23 -17 39 16 16 34 14 41 -5z m366 -3 c8 -16 6 -22 -7 -29
-24 -14 -50 13 -33 33 17 20 27 19 40 -4z m-2594 -2 c10 -17 -13 -36 -27 -22
-12 12 -4 33 11 33 5 0 12 -5 16 -11z m742 -12 c2 -9 -2 -17 -8 -17 -16 0 -41
26 -33 34 12 12 38 1 41 -17z m2225 17 c11 -12 -13 -43 -28 -38 -22 9 -19 44
3 44 10 0 22 -3 25 -6z m-2612 -24 c6 0 7 4 4 10 -3 6 1 7 10 3 13 -5 14 -7 3
-14 -21 -14 -38 -10 -37 9 0 10 3 12 6 5 2 -7 9 -13 14 -13z m-1024 -47 c-3
-10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m2341 -4 c12 -18 11 -24 0
-36 -18 -17 -48 -7 -48 16 0 16 18 41 30 41 3 0 11 -10 18 -21z m747 -7 c8
-20 -17 -45 -37 -37 -24 9 -29 32 -12 49 17 17 40 12 49 -12z m371 2 c10 -38
-30 -60 -55 -30 -9 11 -9 19 1 35 18 29 46 27 54 -5z m-2621 7 c-19 -8 -18 -9
5 -14 20 -4 19 -4 -7 -4 -25 1 -30 4 -21 14 6 7 18 13 27 12 12 0 11 -2 -4 -8z
m376 -11 c6 0 7 5 4 10 -3 6 -1 10 4 10 6 0 11 -11 11 -25 0 -20 -5 -25 -25
-25 -18 0 -25 5 -25 18 0 22 13 38 18 23 2 -6 8 -11 13 -11z m383 14 c17 -7
22 -44 6 -44 -5 0 -7 7 -4 15 5 11 2 13 -12 8 -12 -4 -15 -3 -10 5 4 7 2 12
-5 12 -6 0 -9 -8 -6 -20 6 -22 -8 -28 -16 -6 -5 13 10 36 24 36 4 0 14 -3 23
-6z m382 -17 c2 -19 -30 -42 -46 -32 -12 7 -13 38 -3 49 13 13 46 1 49 -17z
m741 7 c4 -13 -23 -44 -37 -44 -5 0 -14 10 -21 21 -9 17 -8 23 2 30 18 12 51
7 56 -7z m-1877 -34 c0 -5 -7 -7 -15 -4 -8 4 -15 8 -15 10 0 2 7 4 15 4 8 0
15 -4 15 -10z m583 -53 c15 15 6 -32 -10 -49 -20 -22 -37 -24 -29 -3 3 8 1 15
-4 15 -6 0 -10 -6 -10 -12 0 -19 -20 14 -20 33 0 23 42 41 56 23 6 -7 14 -11
17 -7z m377 -15 c0 -41 -51 -57 -70 -23 -15 29 -13 37 13 48 27 13 57 -1 57
-25z m741 12 c9 -11 8 -14 -7 -14 -15 0 -16 -2 -5 -9 12 -8 12 -12 2 -25 -17
-20 -30 -20 -53 1 -22 20 -23 42 -1 58 19 14 47 9 64 -11z m361 9 c19 -17 24
-43 9 -43 -5 0 -12 6 -15 13 -3 6 -3 2 -1 -10 7 -25 -7 -37 -35 -28 -25 8 -37
50 -20 70 16 20 39 19 62 -2z m370 0 c23 -21 23 -36 -2 -58 -17 -15 -24 -16
-45 -7 -30 14 -34 56 -7 71 24 14 33 13 54 -6z m-2269 -8 c-3 -9 -8 -14 -10
-11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m37 -6 c-13 -8 -13 -10 0 -18 8 -6 12
-13 8 -16 -3 -3 -13 2 -22 11 -16 15 -16 17 1 29 10 7 20 11 23 9 2 -3 -2 -9
-10 -15z m1125 4 c19 -26 1 -53 -35 -53 -16 0 -32 5 -35 10 -3 6 -1 10 4 10 6
0 11 5 11 11 0 5 -4 7 -10 4 -14 -9 -13 8 2 23 17 17 49 15 63 -5z m-1155 -38
c11 -13 10 -14 -4 -9 -9 3 -16 10 -16 15 0 13 6 11 20 -6z m974 -64 c20 -17
20 -40 0 -69 -19 -28 -48 -28 -75 -1 -47 47 23 113 75 70z m359 7 c9 -7 19
-25 22 -40 13 -54 -75 -77 -94 -25 -14 36 7 67 50 76 3 0 13 -5 22 -11z m379
-5 c22 -20 23 -51 1 -75 -21 -24 -48 -23 -73 2 -26 26 -25 44 2 70 26 24 45
25 70 3z m370 0 c34 -31 10 -93 -37 -93 -29 0 -45 18 -45 51 0 51 46 75 82 42z
m-1847 -7 c16 -12 18 -19 10 -42 -6 -15 -16 -29 -23 -32 -10 -3 -9 3 4 22 16
25 16 28 1 44 -9 8 -20 12 -24 8 -4 -4 -1 -11 6 -16 21 -13 5 -43 -19 -35 -11
4 -20 2 -20 -4 0 -6 7 -11 15 -11 8 0 15 -4 15 -10 0 -13 -32 0 -47 19 -13 17
-7 51 11 63 18 12 51 9 71 -6z m360 0 c14 -1 25 -18 25 -36 0 -12 -2 -12 -9
-1 -5 8 -11 9 -15 3 -3 -6 -2 -13 4 -17 17 -11 0 -25 -30 -25 -28 0 -49 25
-27 34 7 2 6 5 -4 5 -18 1 -22 32 -5 43 6 4 20 5 31 2 11 -4 25 -7 30 -8z
m-1608 -68 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1410 -54
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m396 -20 c23 -21 27
-32 22 -53 -9 -35 -18 -43 -58 -47 -61 -7 -94 55 -53 99 31 34 54 34 89 1z
m377 4 c21 -23 29 -75 8 -62 -7 4 -8 3 -4 -5 12 -19 -14 -41 -50 -41 -23 0
-34 5 -34 14 0 8 -4 18 -10 21 -6 4 -7 -1 -4 -12 4 -12 0 -9 -12 6 -13 19 -15
26 -5 29 10 3 10 6 1 12 -9 6 -9 12 1 28 23 37 79 42 109 10z m375 -4 c22 -29
18 -62 -10 -90 -29 -28 -54 -26 -92 9 -29 26 -29 52 1 82 28 28 79 28 101 -1z
m340 16 c-3 -5 0 -7 9 -4 17 7 36 -24 36 -60 0 -34 -46 -69 -79 -61 -29 8 -61
41 -61 65 0 32 39 70 71 70 17 0 28 -4 24 -10z m-1525 -14 c0 -3 -11 -17 -25
-32 -25 -27 -33 -18 -15 17 9 16 40 28 40 15z m82 -19 c15 -24 8 -47 -14 -48
-19 0 -20 -1 -5 -7 15 -6 13 -10 -13 -25 -16 -10 -30 -13 -30 -8 0 6 6 11 13
12 6 1 1 5 -13 9 -23 7 -23 8 -4 9 32 1 40 32 14 51 -11 9 -17 19 -13 23 10
10 56 -1 65 -16z m-67 -47 c-11 -4 -27 -5 -35 -2 -11 4 -10 -1 4 -16 22 -25
15 -30 -9 -6 -15 15 -15 18 1 35 13 15 20 16 38 7 20 -11 20 -11 1 -18z m225
-97 c0 -14 2 -15 9 -4 5 8 11 9 15 3 3 -6 2 -13 -4 -17 -5 -3 -10 -11 -10 -17
0 -6 6 -6 15 2 18 15 20 13 28 -21 9 -34 -17 -72 -33 -49 -8 12 -10 8 -8 -15
3 -26 0 -30 -26 -33 -15 -2 -41 2 -57 8 -24 10 -28 17 -24 39 4 20 3 22 -3 7
-12 -27 -23 -6 -18 33 4 34 12 48 22 38 4 -4 2 -13 -5 -21 -9 -10 -8 -15 2
-19 10 -4 13 3 12 26 -3 49 4 57 46 57 30 0 39 -4 39 -17z m390 -1 c32 -30 39
-63 23 -104 -12 -29 -22 -38 -54 -46 -31 -8 -43 -7 -53 4 -8 7 -16 10 -20 7
-3 -4 -6 9 -7 28 0 28 -2 30 -9 14 -14 -35 -22 9 -11 67 0 4 11 16 23 28 27
25 82 26 108 2z m384 -13 c14 -17 26 -40 26 -51 0 -26 -32 -79 -45 -75 -5 2
-15 -2 -22 -9 -20 -20 -86 2 -101 34 -19 41 -12 99 11 85 6 -4 7 -1 3 6 -12
18 24 40 66 41 28 0 42 -7 62 -31z m365 5 c12 -15 21 -41 21 -59 0 -18 -9 -44
-21 -59 -16 -21 -29 -26 -63 -26 -36 0 -46 5 -65 31 -46 62 -7 138 71 139 28
0 42 -6 57 -26z m-927 -205 c27 -11 48 -52 48 -95 0 -32 -21 -74 -37 -74 -7 0
-13 -4 -13 -8 0 -4 -13 -12 -29 -18 -21 -9 -36 -8 -57 1 -38 17 -74 51 -75 73
0 9 -2 26 -4 36 -5 29 26 76 60 91 31 14 60 12 107 -6z m731 5 c38 -18 71 -81
62 -117 -18 -72 -97 -106 -167 -73 -39 19 -63 70 -53 115 7 35 51 78 85 84 14
2 28 5 32 6 4 0 22 -6 41 -15z m-378 -4 c-3 -5 0 -7 8 -4 17 6 52 -38 62 -78
5 -20 2 -26 -14 -31 -12 -3 -21 -11 -21 -19 0 -10 2 -10 10 2 5 8 12 12 15 8
10 -10 -16 -44 -46 -62 -35 -21 -83 -20 -120 1 -24 14 -28 23 -25 48 2 16 1
22 -1 12 -2 -9 -9 -15 -14 -12 -10 7 -12 55 -3 79 10 27 25 18 19 -11 -4 -23
-4 -24 4 -8 5 11 7 30 4 42 -3 14 2 25 14 32 23 13 116 15 108 1z m-2030 -200
c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m62 -72
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m3895 5 c1 -10 2 -31 2
-48 l1 -30 645 -3 c354 -1 648 -6 652 -10 5 -5 -12 -88 -37 -186 -25 -98 -45
-188 -45 -202 0 -13 20 -105 46 -205 25 -100 43 -183 41 -185 -2 -3 -295 -4
-651 -4 l-646 0 0 -27 c0 -16 -3 -39 -7 -52 l-6 -24 -1481 7 c-815 4 -1484 10
-1488 14 -4 4 655 9 1465 11 810 2 1474 6 1477 8 8 7 12 811 5 871 l-6 52
-1397 0 c-1363 0 -1941 8 -1382 20 135 3 822 6 1528 8 1219 2 1282 1 1284 -15z
m-1087 -137 c55 -36 60 -37 72 -20 7 10 13 24 13 31 0 7 4 13 9 13 11 0 14
-187 3 -194 -12 -7 -30 34 -38 84 -4 22 -10 37 -15 35 -4 -3 -11 1 -16 8 -4 8
-21 22 -38 33 -16 10 -33 23 -37 29 -4 5 -47 10 -97 10 -49 0 -84 4 -78 8 7 4
46 6 87 4 67 -3 82 -8 135 -41z m-1145 -326 c-3 -181 -1 -330 3 -330 7 0 4
-19 -10 -55 -2 -7 35 -10 114 -8 126 4 185 18 239 59 25 18 34 20 45 11 11
-10 11 -9 1 4 -10 13 -8 20 9 35 33 30 57 96 69 192 12 91 8 144 -16 226 -16
57 -60 136 -75 136 -6 0 -8 7 -5 16 3 8 2 12 -4 9 -6 -3 -24 4 -41 16 -17 12
-45 25 -63 29 -27 7 -28 8 -6 9 34 1 102 -24 93 -34 -5 -4 -2 -5 5 -1 11 7 60
-21 87 -49 20 -20 62 -91 77 -130 22 -56 29 -238 8 -205 -5 8 -10 10 -10 5 0
-6 5 -16 11 -22 16 -16 -38 -156 -79 -204 -36 -42 -101 -84 -142 -93 -14 -3
-43 -10 -65 -15 -48 -12 -355 -15 -355 -3 0 5 19 7 42 6 22 -1 36 1 30 5 -13
8 -4 51 10 51 5 0 7 -10 3 -22 -5 -20 -4 -21 5 -6 8 13 8 24 0 37 -16 30 -14
678 3 684 6 2 14 -2 17 -9 3 -7 3 -162 0 -344z m170 350 c-8 -5 -27 -10 -41
-10 -14 0 -34 -6 -45 -14 -18 -14 -19 -33 -24 -348 l-6 -333 -1 352 -2 352 47
4 c26 2 56 4 67 5 16 1 17 0 5 -8z m-777 -7 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12
5 14 0 19 -2 13 -5z m117 0 c-14 -3 -32 -11 -42 -20 -11 -9 -18 -11 -23 -4 -3
6 0 11 7 11 9 0 9 3 -2 10 -11 7 -1 9 35 8 28 0 39 -3 25 -5z m127 1 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1234 -21 c-18 -15 -47 -47
-62 -72 -16 -25 -29 -39 -29 -32 0 29 89 130 115 130 6 1 -5 -11 -24 -26z
m-1191 -5 c0 -6 7 -8 15 -4 21 8 72 -61 57 -76 -17 -17 -15 -33 3 -18 13 11
15 8 15 -14 0 -15 -4 -25 -9 -22 -9 6 -24 -35 -16 -43 3 -2 5 1 5 7 0 7 4 12
9 12 5 0 2 -18 -6 -40 -11 -28 -19 -37 -25 -28 -5 9 -8 7 -8 -8 0 -24 -32 -54
-75 -73 l-30 -13 28 -14 c35 -19 35 -27 0 -13 -16 6 -48 14 -73 19 l-45 8 40
1 c93 3 165 83 165 183 0 50 -14 82 -59 131 -19 20 -21 26 -8 22 9 -4 17 -12
17 -17z m-317 -639 c-5 -5 -6 141 -3 324 l5 332 3 -324 c1 -179 -1 -328 -5
-332z m29 369 c-2 -156 0 -320 3 -365 l7 -83 69 1 c39 1 73 -2 76 -5 4 -3 -46
-6 -110 -6 -66 0 -118 4 -122 10 -3 6 -4 10 -1 10 2 0 12 3 22 7 12 4 15 3 10
-5 -4 -7 -1 -12 8 -12 9 0 16 8 17 18 0 16 1 16 7 0 4 -10 5 140 4 333 -4 306
-1 379 10 379 2 0 2 -127 0 -282z m1349 190 c-10 -24 -22 -61 -26 -83 -5 -22
-9 -43 -10 -47 -2 -5 -3 -11 -4 -15 -3 -10 0 -210 3 -239 2 -22 21 -77 37
-107 5 -10 7 -22 4 -28 -4 -5 -1 -8 7 -7 7 2 13 -5 13 -14 0 -9 4 -20 9 -25 5
-5 6 -2 1 7 -4 8 5 3 21 -12 45 -40 97 -57 184 -59 71 -1 82 1 122 28 49 33
85 90 94 151 6 43 28 77 44 67 10 -6 -6 -88 -27 -133 -21 -43 -52 -74 -108
-105 -32 -18 -56 -22 -125 -22 -73 0 -93 4 -143 29 -130 63 -186 171 -188 359
-1 90 20 180 52 220 10 14 19 29 19 33 0 7 30 44 36 44 2 0 -5 -19 -15 -42z
m36 -60 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-15 -40 c-7 -7
-12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-12 -148 l-7 -125 -1 96
c-2 81 5 167 13 157 1 -2 -1 -59 -5 -128z m-1270 0 c0 -5 -2 -10 -4 -10 -3 0
-8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m254 -42 c34 -22 54 -54 75
-119 14 -44 14 -69 1 -69 -5 0 -10 -12 -11 -27 0 -27 -1 -27 -9 -5 -5 12 -11
22 -13 22 -2 0 -2 -10 0 -22 6 -38 -18 -72 -52 -73 -20 0 -30 -5 -30 -15 0
-19 -35 -38 -77 -40 -53 -3 -65 1 -33 11 17 5 41 7 55 4 17 -4 21 -3 11 4 -11
7 -8 12 16 24 16 9 35 23 42 31 15 17 36 22 26 6 -3 -5 -1 -10 5 -10 8 0 9 11
5 31 -5 23 -4 30 5 24 9 -6 10 0 6 21 -4 16 -7 55 -8 87 -1 35 -7 62 -15 69
-16 13 -47 68 -38 68 3 0 20 -10 39 -22z m-354 -294 c0 -8 -4 -12 -10 -9 -5 3
-10 1 -10 -6 0 -7 -3 -9 -6 -6 -4 4 -1 13 6 22 16 19 20 19 20 -1z m350 -8 c0
-3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-1470
-44 c0 -5 -7 -9 -15 -9 -8 0 -12 4 -9 9 3 4 9 8 15 8 5 0 9 -4 9 -8z m40 -2
c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m883
-99 c4 -4 -15 -5 -43 -1 -27 3 -52 9 -55 12 -9 8 89 -3 98 -11z m44 13 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-444 -11 c-89 -2 -237 -2
-330 0 -92 1 -19 3 162 3 182 0 257 -2 168 -3z m194 1 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m98 -4 c3 -5 -3 -10 -14 -10 -11 0 -23 5 -26
10 -3 6 3 10 14 10 11 0 23 -4 26 -10z m225 -95 c77 -32 75 -31 55 -39 -9 -3
-13 -2 -10 4 3 6 -5 10 -19 10 -14 0 -28 5 -31 10 -4 6 -24 18 -46 26 -21 9
-36 21 -33 26 4 7 9 7 13 0 3 -5 35 -22 71 -37z m2015 31 c-51 -35 -315 -152
-385 -171 -33 -8 -67 -18 -165 -45 -183 -50 -448 -69 -695 -50 -138 10 -291
37 -308 55 -6 6 128 -4 138 -10 23 -14 237 -35 355 -35 118 0 305 18 365 35 8
2 42 7 75 11 33 3 66 10 73 16 6 6 12 8 12 4 0 -3 16 1 36 9 20 8 40 13 44 10
5 -3 11 -1 14 4 3 4 20 11 38 15 54 10 335 129 348 146 7 10 32 18 55 19 19 1
19 1 0 -13z m176 -4 c-41 -30 -264 -153 -268 -148 -2 2 38 26 89 54 51 28 113
64 138 81 47 31 82 42 41 13z m-2091 -66 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9
4 6 10 25 14 25 6z m20 -16 c0 -5 -8 -10 -17 -10 -15 0 -16 2 -3 10 19 12 20
12 20 0z m46 -19 c9 -9 156 -61 184 -66 36 -5 70 -18 70 -25 0 -5 -24 -2 -52
7 -29 8 -68 18 -86 23 -18 4 -38 12 -45 17 -7 5 -31 15 -54 23 -24 7 -43 17
-43 22 0 11 14 10 26 -1z m1744 -55 c0 -2 -9 -6 -20 -9 -11 -3 -18 -1 -14 4 5
9 34 13 34 5z m-110 -47 c-36 -17 -78 -32 -95 -35 l-30 -4 30 11 c32 12 75 29
115 47 14 6 30 11 35 11 6 0 -19 -14 -55 -30z m-1655 -7 c-3 -3 -11 0 -18 7
-9 10 -8 11 6 5 10 -3 15 -9 12 -12z m1472 -58 c-3 -3 -12 -4 -19 -1 -8 3 -5
6 6 6 11 1 17 -2 13 -5z m-47 -14 c-8 -5 -22 -9 -30 -9 -10 0 -8 3 5 9 27 12
43 12 25 0z m-53 -16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-50 -10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-44 -11 c-7
-2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-826 -9 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m743 -4 c-14 -4 -38 -8 -55 -8 -29 1
-29 1 5 8 50 11 83 11 50 0z m-693 -6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m548 -14 c-27 -5 -77 -8 -110 -7 l-60 1 70 6 c39 4 88 7 110 7
37 0 37 0 -10 -7z m-342 -7 c-18 -2 -48 -2 -65 0 -18 2 -4 4 32 4 36 0 50 -2
33 -4z m132 -3 c-3 -5 -15 -10 -25 -10 -10 0 -22 5 -25 10 -4 6 7 10 25 10 18
0 29 -4 25 -10z"/>
<path d="M5386 4582 c-2 -4 4 -8 14 -8 10 0 16 4 14 8 -3 4 -9 8 -14 8 -5 0
-11 -4 -14 -8z"/>
<path d="M3910 4570 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5576 4495 c7 -18 20 -19 30 -3 4 7 3 8 -4 4 -7 -4 -12 -2 -12 3 0 6
-4 11 -10 11 -5 0 -7 -7 -4 -15z"/>
<path d="M4460 4490 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M4650 4408 c0 -12 7 -18 23 -17 15 0 17 2 7 6 -8 3 -18 11 -22 17 -5
7 -8 4 -8 -6z"/>
<path d="M4631 4394 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5036 4383 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M5186 4294 c-9 -24 -7 -27 9 -10 17 17 19 26 6 26 -5 0 -12 -7 -15
-16z"/>
<path d="M5230 4300 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5226 4254 c-4 -10 -1 -14 6 -12 15 5 23 28 10 28 -5 0 -13 -7 -16
-16z"/>
<path d="M5550 4260 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4453 4298 c-19 -25 -24 -38 -14 -38 6 0 11 7 11 16 0 14 2 14 16 -1
22 -21 30 -12 11 15 -11 16 -16 18 -24 8z"/>
<path d="M4825 4300 c-3 -6 1 -13 10 -16 21 -9 26 -2 10 13 -9 8 -16 9 -20 3z"/>
<path d="M4840 4250 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5031 4170 c0 -8 4 -22 9 -30 12 -18 12 -2 0 25 -6 13 -9 15 -9 5z"/>
<path d="M5050 4111 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M6180 4100 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M4912 3935 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M5173 3990 c-11 -16 -10 -18 2 -14 8 4 15 12 15 20 0 19 -3 18 -17
-6z"/>
<path d="M5230 4006 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M5191 3962 c-8 -15 -5 -18 17 -19 15 0 19 1 10 4 -10 2 -18 9 -18 15
0 5 7 7 16 4 8 -3 12 -2 9 4 -9 15 -23 12 -34 -8z"/>
<path d="M5182 3900 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5610 4004 c0 -7 27 -34 34 -34 4 0 1 9 -6 20 -12 19 -28 27 -28 14z"/>
<path d="M5580 3946 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M5122 3700 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M5028 3603 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5862 3700 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5476 3723 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M5341 3664 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7374 3405 c-3 -6 -3 -172 -2 -370 l3 -360 633 -3 c588 -2 632 -1
632 15 0 9 -19 93 -42 186 l-42 169 42 167 c23 91 42 174 42 184 0 16 -44 17
-631 19 -416 2 -633 0 -635 -7z"/>
<path d="M5123 3020 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M5632 3005 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M4840 3363 c0 -7 7 -18 15 -27 8 -8 15 -19 15 -25 0 -6 5 -11 10 -11
14 0 13 2 -15 40 -14 19 -25 29 -25 23z"/>
<path d="M5821 3184 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4897 2951 c15 -28 29 -47 31 -41 2 6 -3 19 -12 29 -9 10 -16 22 -16
28 0 6 -7 16 -15 23 -9 7 -3 -10 12 -39z"/>
<path d="M4897 2889 c4 -13 8 -18 11 -10 2 7 -1 18 -6 23 -8 8 -9 4 -5 -13z"/>
<path d="M4932 2855 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M5025 2260 c27 -12 43 -12 25 0 -8 5 -22 9 -30 9 -10 0 -8 -3 5 -9z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
